export function NewsFilter(){
	var newsFilter = document.querySelector('.hero--news .section-content--projects');

	if (newsFilter == null){
		return;
	}

	var filters = newsFilter.querySelectorAll('.projects-links input');
	var URLParams = new URLSearchParams(document.location.search);
	var qsCategory = URLParams.get('category');
	var selectedFilters;

	if (qsCategory != null){
		selectedFilters = qsCategory.split('+');
	}

	var updateQs = function(val){
		var qs = [];

		filters.forEach(function(el){
			if (el.checked){
				qs.push(el.value);
			}
		});

		var url = new URL(window.location.href);
		url.searchParams.set("category", qs.join('+'));

		//window.history.pushState({}, '', url);
		//window.location.href = url;

		console.log('url: ', url);

		fetch(url)
			.then(response => response.text())
			.then(data => {
				var parser = new DOMParser();
				var doc = parser.parseFromString(data, 'text/html');
				var el = doc.querySelector('.cards-container');
				var container = document.querySelector('#news-container')

				container.innerHTML = el.innerHTML;
			})
			.then(() => {
				window.history.pushState({}, '', url);
			});
	}

	filters.forEach(function(el,i){
		// check filters already selected
		if (qsCategory != null && selectedFilters.includes(el.value)){
			el.checked = true;
		}		

		// look for select/deselect
		el.addEventListener('change', function() {
			updateQs(el)
		});
	});
}