
console.log('webpack says hello');

export function CardGallery(){
	var cgs = document.querySelectorAll('.card-gallery');
	
	if (!cgs.length) {
		return;
	}

	cgs.forEach(element => {
		var cardButtons = element.querySelectorAll('.card__open-button');
		var cardCloseButtons = element.querySelectorAll('.card__close-button');
		var cards = element.querySelectorAll('.column');

		cardButtons.forEach(element => {
			element.addEventListener('click', function(){
				var card = this.closest('div.card');
				var content = card.querySelector('.card__content');

				card.style.transition = 'all 1s linear';

				if (card.classList.contains('is-open')){
					card.classList.remove('is-open');
					card.style.height = 'auto';
				}
				else {
					//make sure nothing is open
					[].forEach.call(cards, function (el) {
						el.classList.remove('is-open');
						el.style.height = 'auto';
					});

					card.classList.add('is-open');
					
					card.style.height = card.offsetHeight + content.offsetHeight + 'px';
					
				}

				

				console.log('h: ', content.offsetHeight);
			});
		});

		cardCloseButtons.forEach(element => {
			element.addEventListener('click', function () {
				[].forEach.call(cards, function (el) {
					el.classList.remove('is-open');
					el.style.height = 'auto';
				});
			});
		});
	});
}