export function CardLoader(pageSize = 9){
	var clSection = document.querySelector('.cards-section--loader');

	if (clSection == null){
		return;
	}

	var state = {};
	var URLParams = new URLSearchParams(document.location.search);
	var qsPageNum = URLParams.get('pageNum');
	
	//do we have a pageNum? Default to page 1 if no
	state.pageNum = isNaN(qsPageNum) || qsPageNum == null ? state.pageNum = 1 : state.pageNum = parseInt(qsPageNum);

	var url = new URL(window.location.href);
	url.searchParams.set("pageNum", state.pageNum);

	window.history.pushState(state, '', url);
		
	console.log(state.pageNum);

	var clSectionCards = clSection.querySelectorAll('.column');
	var clSectionMore = clSection.querySelector('.anchor-container');

	var updateCards = function(page){
		var count = page * pageSize;

		clSectionCards.forEach(function(value, i) {
			if (i >= count) {
				value.classList.add('hide');
			}
			else {
				value.classList.remove('hide');
			}
		});

		if (count >= clSectionCards.length){
			clSectionMore.classList.add('hide');
		}
		else {
			clSectionMore.classList.remove('hide');
		}
	};

	if (clSectionMore != null){
		clSectionMore.addEventListener('click', function (event) {
			event.preventDefault();

			state.pageNum = state.pageNum + 1;

			var url = new URL(window.location);
			url.searchParams.set('pageNum', state.pageNum);
			window.history.pushState(state, '', url);

			updateCards(state.pageNum);
		});
	}	

	window.addEventListener('popstate', function () {
		var url = new URL(window.location);
		
		updateCards(url.searchParams.get('pageNum'));
	})

	updateCards(state.pageNum);
}