export function WorkFilter() {
	var workFilter = document.querySelector('.hero--work .section-content--projects');

	if (workFilter == null) {
		return;
	}

	var filters = workFilter.querySelectorAll('.projects-links a');

	var updateQs = function (val) {
		var url = new URL(window.location.href);
		url.searchParams.set("category", val.dataset.category);

		console.log('url: ', url);

		filters.forEach((el) => {
			el.classList.remove('active');
		});

		fetch(url)
			.then(response => response.text())
			.then(data => {
				var parser = new DOMParser();
				
				var resultsDoc = parser.parseFromString(data, 'text/html'); //parse returned data as DOM
				var resultsEl = resultsDoc.querySelector('.cards-container'); //results from ajax request
				var resultsIntroEl = resultsDoc.querySelector('#work-intro');

				var pageContainer = document.querySelector('#work-container'); //page we're on
				var pageIntroEl = document.querySelector('#work-intro');

				//console.log('intro: ', resultsIntroEl);

				pageContainer.innerHTML = resultsEl.innerHTML;
				pageIntroEl.innerHTML = resultsIntroEl.innerHTML;
			})
			.then(() => {
				window.history.pushState({}, '', url);
				val.classList.add('active');
			});
	}

	filters.forEach(function (el, i) {
		// look for click
		el.addEventListener('click', function (e) {
			e.preventDefault();

			updateQs(el);
		});
	});
}