import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName('splide');
for (var i = 0, len = elms.length; i < len; i++) {
	new Splide(elms[i], {
		perPage: 1.2,
		focus: 'center',
		height: '76.8rem',
		autoWidth: true,
		arrows: true,
		type: 'loop',
		pagination: false,
		breakpoints: {
			640: {
				height: '20rem',
				arrows: true,
				perPage: 1.2,
				autoWidth: false,
			}
			
		}
	}).mount();
}