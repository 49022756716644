import './main.scss';
import './hero/hero-splide';

import { CardGallery } from './cards/card-gallery';
import { CardLoader } from './cards/card';
import { NewsFilter } from './header/news-filter';
import { WorkFilter } from './header/work-filter';

function init() {
	CardGallery();
	CardLoader();
	NewsFilter();
	WorkFilter();

    var fixedAnchor = document.querySelector('.about-anchor');

    if (fixedAnchor != null){
        document.addEventListener('scroll', function() {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                fixedAnchor.classList.add("anchor-background");
            } else {
                fixedAnchor.classList.remove("anchor-background");
            }
        });
    }
    
}

init();